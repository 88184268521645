<template>
  <div class="error">
    <page404 />
  </div>
</template>

<script>
import page404 from "../components/page404.vue";

export default {
  name: "error",
  components: {
    page404,
  },
  metaInfo() {
    return {
      title: "Powering digital payments and identity verification",
      meta: [
        {
          name: "Powering digital payments and identity verification",
          content:
            "Accept online payments with card, FPX and e-Wallet in less than a minute using Tekkis no-code solutions. No Tech team or website needed. Tekkis also offers API integrations for both eKYC and online payments.",
        },
        {
          property: "og:title",
          content: "Powering digital payments and identity verification",
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },
};
</script>
